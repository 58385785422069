'use strict';

angular.module('m42AccessControlServiceFrontendApp')
    .service('M42Translation', ['$state', '$translate', '$location', 'M42Helpers', 'M42Validation', 'mxAuthAcsSessionClient','$stateParams',
        function ($state, $translate, $location, M42Helpers, M42Validation, mxAuthAcsSessionClient, $stateParams) {
            var service = this;

            var language;
            var defaultLanguage = "english";
            var savedPreferredLanguageFromUri;
            var savedPreferredLanguageFromChangeLanguageFunction;

            function mapLanguageKeyToName(key) {
                if (key) {
                    key = key.toLocaleLowerCase();

                    switch (key) {
                        // english
                        case 'en-us':
                        case 'en_us':
                        case 'en-gb':
                        case 'en_gb':
                        case 'eng':
                        case 'english':
                        case 'en':
                            return 'english';

                        // ukrainian
                        case 'uk-ua':
                        case 'uk_ua':
                        case 'ukr':
                        case 'ukrainian':
                        case 'ua':
                            return 'ukrainian';

                        // dutch
                        case 'nl-nl':
                        case 'nl_nl':
                        case 'nl':
                        case 'dutch':
                            return 'dutch';

                        // german
                        case 'de-de':
                        case 'de_de':
                        case 'de-au':
                        case 'de_au':
                        case 'de-ch':
                        case 'de_ch':
                        case 'deu':
                        case 'deutsch':
                        case 'de':
                        case 'german':
                            return 'german';

                        //french
                        case 'fr-fr':
                        case 'fr_fr':
                        //fr-BE French - Belgium
                        case 'fr-be':
                        case 'fr_be':
                        //fr-CA French - Canada
                        case 'fr-ca':
                        case 'fr_ca':
                        //fr-LU	French - Luxembourg
                        case 'fr-lu':
                        case 'fr_lu':
                        //fr-MC	French - Monaco
                        case 'fr-mc':
                        case 'fr_mc':
                        //fr-CH	French - Switzerland
                        case 'fr-ch':
                        case 'fr_ch':
                        //french
                        case 'fr':
                        case 'french':
                            return 'french';
                    }
                }

                return 'english';
            }

            function mapLanguageNameToKey(name) {
                switch (name) {
                    case 'english':
                        return 'en-US';

                    case 'ukrainian':
                        return 'uk-UA';

                    case 'dutch':
                        return 'nl-NL';

                    case 'german':
                        return 'de-DE';

                    case 'french':
                        return 'fr-FR';
                }

                return 'en-US';
            }

            function getCultureFromToken() {
                var session = mxAuthAcsSessionClient.getActiveSession();
                return session && session.claims.culture;
            }


            var getPreferredLanguageFromBrowser = function () {
                return navigator.language ||
                    navigator.browserLanguage ||
                    navigator.systemLanguage ||
                    navigator.userLanguage;
            };

            var getPreferredLanguageFromUri = function (route) {
                var params = route.params || $stateParams;
                var lang;

                if (params !== undefined) {
                    lang = params.lang;
                    if (!M42Validation.isEmpty(lang)) {
                        delete $location.$$search.lang;
                        savedPreferredLanguageFromUri = lang;
                        return lang;
                    }
                }

                lang = M42Helpers.parseQueryString(window.location.search).lang;
                if (!M42Validation.isEmpty(lang)) {
                    savedPreferredLanguageFromUri = lang;
                    return lang;
                }

                return undefined;
            };

            var getPreferredLanguage = function (route) {

                //savedPreferredLanguageFromChangeLanguageFunction = localStorage.getItem('savedPreferredLanguageFromChangeLanguageFunction');

                var lang = savedPreferredLanguageFromChangeLanguageFunction ||
                    getPreferredLanguageFromUri(route) ||
                    savedPreferredLanguageFromUri ||
                    getCultureFromToken() ||
                    getPreferredLanguageFromBrowser() ||
                    $translate.preferredLanguage() ||
                    defaultLanguage;

                return mapLanguageKeyToName(lang);
            };

            service.initialize = function (route) {
                var lang = getPreferredLanguage(route);
                if (language !== lang) {
                    language = lang;
                    $translate.use(lang);
                    $translate.refresh();
                    $location.path($location.path());
                }
            };

            service.changeLanguage = function (newLang) {
                var lang = mapLanguageKeyToName(newLang);
                savedPreferredLanguageFromChangeLanguageFunction = lang;
                //localStorage.setItem('savedPreferredLanguageFromChangeLanguageFunction', savedPreferredLanguageFromChangeLanguageFunction);
                
                if (language !== lang) {
                    language = lang;
                    $translate.use(lang);
                    $translate.refresh();
                    $location.path($location.path());
                }
            };

            service.getCurrentLanguage = function () {
                return mapLanguageNameToKey(language);
            };

            service.getAvailableLanguages = function () {
                var availableKeys = ['de-DE', 'en-US', 'fr-FR', 'nl-NL', 'uk-UA'];
                return availableKeys.map(function (key) {
                    return {
                        value: key,
                        name: 'Languages.' + key.replace('-', '_')
                    };
                });
            };
        }]);
