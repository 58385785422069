'use strict';

angular.module('m42AccessControlServiceFrontendApp')
    .controller('MyaccountTokensDeleteCtrl', ['token', '$scope', '$uibModalInstance',
        function (token, $scope, $modalInstance) {

            $scope.token = token;

            $scope.ok = function () {
                $modalInstance.close();
            };

            $scope.cancel = function () {
                $modalInstance.dismiss();
            };

        }]);
