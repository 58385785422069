"use strict";

 angular.module('config', [])

.constant('$globalConfiguration', {
    name: 'distribution',
    apiEndpoint: '',
    apiOAuthAuthorizePath: '/issue/oauth2/authorize',
    apiOAuthSignOutPath: '/issue/oauth2/signout',
    apiOAuthClientID: 'accountsmatrix42com',
    apiOAuthScope: 'urn:matrix42com',
    deploymentUUID: '6db6860c-56b6-4fe5-b6a5-5a55f3521fd8',
    redirectSavedUrls: ['lang']
})

;