'use strict';

angular.module('m42AccessControlServiceFrontendApp')
    .directive('myaccountTokens', function () {
        return {
            templateUrl: 'partials/directives/myaccount_tokens.html',
            controller: 'myaccountTokensCtrl',
            restrict: 'E',
            scope: {
                error: '&'
            }
        };
    })
    .controller('myaccountTokensCtrl', ['$scope', '$m42AcsBackend', '$busy', '$uibModal',
        function ($scope, $m42AcsBackend, $busy, $modal) {

            $scope.expirationDateSuggestions = [90, 180, 360].map(function (days) {
                return {
                    days: days, date: getDateAfterDays(days)
                };
            });
            $scope.tokens = [];
            $scope.newToken = {
                Name: '',
                ExpirationDate: ''
            };

            loadTokens();

            $scope.createToken = function () {
                if (!$scope.newToken.Name || !$scope.newToken.ExpirationDate) { return; }

                return $busy.during($m42AcsBackend.sessionTokens.create($scope.newToken).$promise)
                    .then(function (fullToken) {
                        $scope.tokens.push(fullToken);
                        $scope.newToken = {
                            Name: '',
                            ExpirationDate: ''
                        };

                        $modal.open({
                            templateUrl: 'partials/modals/modal_myaccount_tokens_created.html',
                            controller: 'MyaccountTokensCreatedCtrl',
                            resolve: {
                                token: function () {
                                    return fullToken;
                                }
                            }
                        });
                    })
                    .catch(function (error) {
                        $scope.error({code: 500, message: error.data || error.statusText});
                    });
            };

            $scope.deleteToken = function (token) {
                return $modal.open({
                    templateUrl: 'partials/modals/modal_myaccount_tokens_delete.html',
                    controller: 'MyaccountTokensDeleteCtrl',
                    resolve: {
                        token: function () {
                            return token;
                        }
                    }
                }).result.then(function () {
                    return $busy.during($m42AcsBackend.sessionTokens.delete({id: token.TokenSignature}).$promise)
                        .then(function () {
                            var index = $scope.tokens.indexOf(token);
                            if (index > -1) {
                                $scope.tokens.splice(index, 1);
                            }
                        })
                        .catch(function (error) {
                            $scope.error({code: 500, message: error.data || error.statusText});
                        });
                });
            };

            function getDateAfterDays(days) {
                return new Date(Date.now() + 1000 * 60 * 60 * 24 * days);
            }

            function loadTokens() {
                return $busy.during($m42AcsBackend.sessionTokens.get().$promise)
                    .then(function (tokens) {
                        $scope.tokens = tokens;
                    })
                    .catch(function (error) {
                        $scope.error({code: 500, message: error.data || error.statusText});
                    });
            }

        }
    ]);
