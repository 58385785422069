angular.module('m42AccessControlServiceFrontendApp')
    .controller('EnterpriseGroupCtrl', ['enterprise', 'roleId', 'isNew', '$scope', '$uibModalInstance', 'M42Validation', '$m42AcsBackend', '$m42AcsHost', '$busy',
        function (enterprise, roleId, isNew, $scope, $modalInstance, M42Validation, $m42AcsBackend, $m42AcsHost, $busy) {
            $scope.groupId = roleId;
            $scope.groupName = '';
            $scope.errorCode = -1;
            $scope.search = '';
            $scope.accounts = [];
            $scope.filteredAccounts = [];
            $scope.filteredAccountsOnCurrentPage = [];
            $scope.currentPage = 1;


            function updateFilteredAccounts() {
                if (!$scope.search) {
                    $scope.filteredAccounts = $scope.accounts;
                } else {

                    var filtered = [],
                        lowerSearch = $scope.search.toLowerCase();

                    angular.forEach($scope.accounts, function (account) {
                        if (account.AccountName.toLowerCase().indexOf(lowerSearch) > -1 ||
                            (account.FirstName && account.FirstName.toLowerCase().indexOf(lowerSearch) > -1 ) ||
                            (account.LastName && account.LastName.toLowerCase().indexOf(lowerSearch) > -1)) {
                            filtered.push(account);
                        }
                    });

                    $scope.filteredAccounts = filtered;
                }

                $scope.updateFilteredAccountsOnCurrentPage();
            }

            $scope.$watch('search', updateFilteredAccounts);
            $scope.$watch('accounts', updateFilteredAccounts);


            // Load list of accounts
            function loadRoleDetails() {
                // First get all accounts in the enterprise
                $m42AcsBackend.enterprise.accounts({id: enterprise.Id}, function (accountInEnterprise) {

                    if (!isNew) {
                        // Then get accounts assigned to the current company specific role.
                        $m42AcsBackend.enterprise.getEnterpriseSpecificRoleDetails({
                            id: enterprise.Id,
                            roleId: $scope.groupId
                        }, function (roleDetails) {
                            $scope.groupName = roleDetails.Role;
                            $scope.accounts = generateAccountsForGroup(accountInEnterprise, roleDetails.Accounts);
                        });
                    } else {
                        $scope.accounts = generateAccountsForGroup(accountInEnterprise, []);
                    }
                });
            }

            // Returns a collection of accounts that belongs to that Company with flag(IsAssigned) which means - does account
            // belong to that Company Specific group.
            function generateAccountsForGroup(accountsInEnterprise, accountsInRole) {
                var accounts = [];

                angular.forEach(accountsInEnterprise, function (account, index) {

                    var isAssigned = false;

                    if (accountsInRole.indexOf(account.MailAddress) > -1) {
                        isAssigned = true;
                    }

                    accounts.push({
                        AccountName: account.MailAddress,
                        FirstName: account.FirstName,
                        LastName: account.LastName,
                        IsAssigned: isAssigned
                    });
                });

                return accounts;
            }

            loadRoleDetails();


            $scope.isEnabled = function () {
                return isNew;
            };

            $scope.isNew = function () {
                return isNew;
            };

            $scope.formIsValid = function () {

                var isValid = false;

                if (!M42Validation.isEmpty($scope.groupName)) {
                    isValid = true;
                }

                return isValid;
            };

            $scope.updateFilteredAccountsOnCurrentPage = function () {
                var pageStart = ($scope.currentPage - 1) * 10,
                    pageEnd = Math.min($scope.currentPage * 10, $scope.filteredAccounts.length);

                $scope.filteredAccountsOnCurrentPage = $scope.filteredAccounts.slice(pageStart, pageEnd);
            };

            //Calls ACS API to update(assign/unassign) accounts to Company specific role.
            $scope.assignAccountsToCompanyGroup = function (enterpriseId, accounts) {
                //Assign users to role
                //Collect only marked accounts
                var assignedAccounts = [];

                angular.forEach(accounts, function (account, index) {

                    if (account.IsAssigned) {
                        assignedAccounts.push(account.AccountName);
                    }
                });

                //ACS API Call to assign accounts to a specified company role.
                $busy.during($m42AcsBackend.enterprise.assignAccountsToEnterpriseSpecificRole({
                            id: enterpriseId,
                            roleId: $scope.groupId
                        },
                        {
                            Accounts: assignedAccounts
                        }).$promise.then(function () {

                            // no error, good
                            $scope.errorCode = 200;
                            $scope.errorMessage = '';

                            $modalInstance.close();

                        }).catch(function (response) {
                            $scope.errorCode = response.code;
                            $scope.errorMessage = response.message;
                        })
                );
            };

            //This method performs an ACS API call to assign/unassign accounts from Company specific role.
            $scope.ok = function () {

                //If we are creating a new Company specific role, then we need to perform two steps:
                //1. Create a new Company specific role
                //2. Assign accounts that were assigned by administrator to that role.
                if (isNew) {
                    //Call create an enterprise specific role,
                    //then we need to send an accounts collection only with accounts that currently were assigned to that
                    //Company specific role.
                    $busy.during($m42AcsBackend.enterprise.createEnterpriseSpecificRole({id: enterprise.Id},
                            {
                                Role: $scope.groupName
                            }).$promise.then(function (newId) {

                                $scope.groupId = newId;
                                $scope.assignAccountsToCompanyGroup(enterprise.Id, $scope.accounts);

                                // no error, good
                                $scope.errorCode = 200;
                                $scope.errorMessage = '';

                                $modalInstance.close();

                            }).catch(function (response) {
                                $scope.errorCode = response.code;
                                $scope.errorMessage = response.message;
                            })
                    )
                }
                else {
                    $scope.assignAccountsToCompanyGroup(enterprise.Id, $scope.accounts);
                }

                // reset the busy message
                $busy.setMessage('');
            };

            $scope.cancel = function () {
                $modalInstance.dismiss("cancel");
            };

        }]);
