'use strict';

angular.module('m42AccessControlServiceFrontendApp')
    .controller('MyaccountTokensCreatedCtrl', ['token', '$scope', '$uibModalInstance',
        function (token, $scope, $modalInstance) {

            $scope.token = token;

            $scope.ok = function () {
                $modalInstance.close();
            };

        }]);
