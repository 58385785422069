'use strict';

/**
 * @ngdoc directive
 * @name m42AccessControlServiceFrontendApp.directive:enterpriseGroups
 * @description
 * # enterpriseGroups
 */
angular.module('m42AccessControlServiceFrontendApp')
    .directive('enterpriseGroups', function () {
        return {
            templateUrl: 'partials/directives/enterprise_groups.html',
            controller: 'enterpriseGroupsCtrl',
            restrict: 'C',
            scope: {
                enterprise: '=',

                error: '&'
            }
        };
    }).controller('enterpriseGroupsCtrl', ['$scope', '$controller', '$uibModal', '$m42AcsBackend', '$translate', '$busy', function ($scope, $controller, $modal, $m42AcsBackend, $translate, $busy) {
    // inject the mixins
    angular.extend(this, $controller('EnterpriseGroupCtrlMixin', {$scope: $scope, $modal: $modal}));

    $scope.openEnterpriseGroupDialogParent = function (enterprise, roleId, isNew) {
        $scope.openEnterpriseGroupDialog(enterprise, roleId, isNew).then(function () {

            // refresh the list of company groups, may be we created a new one.
            $scope.enterpriseRoles = $scope.getCompanySpecificRoles($scope.enterprise.Id);

        });
    };

    $scope.$watch('enterprise', function () {
        if ($scope.enterprise && $scope.enterprise !== undefined && $scope.enterprise.AccountName && $scope.enterprise.AccountName !== undefined) {
            $scope.getCompanySpecificRoles($scope.enterprise.Id);
        }
    });

    //Calls an ACS API to get a collection of Company specific roles by enterpriseId.
    $scope.getCompanySpecificRoles = function () {

        $busy.during($m42AcsBackend.enterprise.roles({id: $scope.enterprise.Id}).$promise).then(function (enterpriseRoles) {

            // assign the roles to the scope
            $scope.enterpriseRoles = enterpriseRoles;

        }).catch(function () {
            //debugger;
        });
    };

    //Calls an ACS API to remoce a Company specific role.
    $scope.removeCompanySpecificRole = function (enterprise, roleId) {

        //deleteAccountsToEnterpriseSpecificRole
        $busy.during($m42AcsBackend.enterprise.deleteAccountsToEnterpriseSpecificRole({
            id: enterprise.Id,
            roleId: roleId
        }).$promise).then(function () {

            angular.forEach($scope.enterpriseRoles, function (role, index) {

                if (role.Id === roleId) {
                    $scope.enterpriseRoles.splice(index, 1);
                }

            });

        }).catch(function (result) {
            $scope.error({code: result.status, message: result.statusText});
        });
    };
}]);
