angular.module('m42AccessControlServiceFrontendApp').controller('EnterpriseGroupCtrlMixin', [ '$scope', '$uibModal', function($scope, $modal) {

    //Opens a model view with users and group they were assigned to.
    $scope.openEnterpriseGroupDialog = function(enterprise, roleId, isNew) {

        var modalInstance = $modal.open({
            templateUrl: 'partials/modals/modal_enterprise_group.html',
            controller: 'EnterpriseGroupCtrl',
            resolve: {
                enterprise: function() {
                    return enterprise;
                },
                roleId: function() {
                    return roleId;
                },
                isNew: function() {
                    return isNew;
                }
            }
        });

        // returns a promise which contains the invited user
        return modalInstance.result;
    };
}]);
