'use strict';

/**
 * @ngdoc directive
 * @name m42AccessControlServiceFrontendApp.directive:enterpriseUsers
 * @description
 * # enterpriseUsers
 */
angular.module('m42AccessControlServiceFrontendApp').directive('enterpriseUsers', function () {
    return {
        templateUrl: 'partials/directives/enterprise_users.html',
        controller: 'enterpriseUsersCtrl',
        restrict: 'C',
        scope: {
            enterprise: '=',
            error: '&'
        }
    };
}).controller('enterpriseUsersCtrl', ['$scope', '$controller', '$uibModal', '$m42AcsBackend', '$translate', '$busy', 'mxAuthAcsSessionClient', function($scope, $controller, $modal, $m42AcsBackend, $translate, $busy, mxAuthAcsSessionClient) {
    // inject the mixins
    angular.extend(this, $controller('EnterpriseInviteUserCtrlMixin', {$scope: $scope, $modal: $modal }));

    $scope.enterpriseAccounts = [];

    $scope.$watch('enterprise', function() {
      if ($scope.enterprise && $scope.enterprise.Id !== undefined) {
        fetchEnterpriseAccounts($scope.enterprise.Id);
      }
    });

    function fetchEnterpriseAccounts(){
      $busy.during($m42AcsBackend.enterprise.accounts({id: $scope.enterprise.Id}).$promise)
        .then(function (accounts) {
          $scope.enterpriseAccounts = accounts;
        }).catch(function() {

        });
    }


    // other method
    $scope.removeUserFromEnterprise = function(email) {
        $translate('EnterpriseUsers.RemoveUserMessage', {name: email}).then(function(msg) {
            var deleteUser = window.confirm(msg);

            if (deleteUser) {

                // remove the enterprise account from the service
                $busy.during($m42AcsBackend.enterprise.leave({id: $scope.enterprise.Id, user: email}).$promise).then(function() {

                  // remove our account from the profiles
                  $scope.enterpriseAccounts = $scope.enterpriseAccounts.filter(function (user) {
                    return user.MailAddress !== email;
                  });
                }).catch(function(result){
                  $scope.error({code: result.code.status, message: result.code.statusText});
                });
            }
        });
    };

    $scope.isAccountSelf = function(account) {
        var session = mxAuthAcsSessionClient.getActiveSession();
        return session.profile.UserId === account.MailAddress;
    };
}]);
